<template>
    <!--新增对话框-->
    <el-dialog
            :title="editForm.acquisitionTaskId?'编辑配置':'新增配置'"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">
            <el-form-item label="采集地址：" prop="acquisitionTaskUrl" label-width="150px">
                <el-input v-model="editForm.acquisitionTaskUrl" autocomplete="off" style="width: 550px"></el-input>
            </el-form-item>
            <el-form-item label="采集页码：" prop="twoNextPageUrl" label-width="150px">
                <el-input v-model="editForm.twoNextPageUrl" autocomplete="off" style="width: 550px"></el-input>
            </el-form-item>
            <el-form-item align="center">
                <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: "AddUpdateDouyin",
        components:{

        },
        data(){
            return{
                timeFormatList:[],
                editForm:{},
                dialogVisible:false,
                editFormRules: {
                    acquisitionTaskUrl: [
                        {required: true, message: '请输入吴江新闻地址', trigger: 'blur'}
                    ],
                    twoNextPageUrl:[
                        {required: true, message: '请输入采集页码', trigger: 'blur'}
                    ]
                },
            }
        },
        methods:{
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
                this.timeFormatList=[]
            },
            handleClose() {
                this.resetForm('editForm')
            },

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/admin/acquisitiontask/' + (this.editForm.acquisitionTaskId?'updateAcquisitionTas' : 'addAcquisitionTas'), this.editForm)
                            .then(res => {

                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    onClose:() => {
                                        this.$emit('refreshDataList')
                                    }
                                });
                                this.dialogVisible = false
                                this.resetForm(formName)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/acquisitiontask/getAcquisitionTasInfo' ,{acquisitionTaskId:id}).then(res => {
                        this.editForm = res.data
                    })
                }else {
                    this.editForm.acquisitionType=6;
                }
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
